<template>
<div>
    <vs-card>
        <div slot="header">
            <vs-row vs-type="flex" vs-justify="space-between">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                <h3>Holiday List</h3>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="felx" vs-w="6">
                <vs-button v-if="this.logged_in_team == 'HR'" type="gradient" @click="addHoliday">Add Holiday</vs-button>
            </vs-col>
            </vs-row>
        </div>

        <vs-table :data="holiday_list">
        
        <template slot="thead">
            <vs-th v-for="(heading, index) in table_headings" :key="index">
                {{heading}}
            </vs-th>
            
        </template>

        <template slot-scope="{data}">
            <vs-tr :state="holiday.displayDay == 'Monday' ? 'success': holiday.displayDay == 'Sunday' ? 'primary':null" :key="index" v-for="(holiday, index) in data" >
            <vs-td >
                <vs-row>
                    <vs-col vs-w="2"><vs-button v-if="logged_in_team == 'HR'" radius color="primary" type="flat" icon="edit" @click="editHoliday(holiday)"></vs-button></vs-col>
                    <vs-col vs-type="flex" vs-align="center" vs-w="10">{{holiday.name}}</vs-col>
                </vs-row>
                
                
            </vs-td>

            <vs-td >
                {{holiday.displayDate}}
            </vs-td>

            <vs-td >
                {{holiday.displayDay}}
            </vs-td>

            <vs-td v-for="(city, index) in holiday.cities" :key="index">
                <div v-if="city.is_holiday === 0" @click="updateHoliday(holiday, city)">N/A</div>
                <div v-if="city.is_holiday === 1" @click="updateHoliday(holiday, city)">{{city.code}} </div>
            </vs-td>
            </vs-tr>
        </template>
        </vs-table>
    </vs-card>
    <vs-popup title="Please Confirm" :active.sync="popupActivo" v-if="selectedHoliday !== null">
        <p>
            Are you sure you want to 
            <b v-if="selectedCity.is_holiday === 0"> ENABLE </b>
            <b v-if="selectedCity.is_holiday === 1"> DISABLE </b>
            {{selectedHoliday.name}} for {{selectedCity.name}}?
        </p>
        
        <vs-divider/>
        <vs-row vs-type="flex" vs-justify="flex-end">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                <vs-button color="success" type="filled" @click="postToServer">Yes</vs-button>&nbsp;
                <vs-button color="danger" type="filled" @click="closePopup">No</vs-button>
            </vs-col>
        </vs-row>

    </vs-popup>
    <vs-popup :title="`${holidayPopupAction} Holiday`" :active.sync="holidayPopupActivo">
        <vs-row vs-type="flex" >
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
                Name
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
                <vs-input v-model="holidayFormData.holiday_name"/>
            </vs-col>
        </vs-row>

        <vs-row vs-type="flex" >
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
                Date
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
                <vs-input type="date" v-model="holidayFormData.holiday_date"/>
            </vs-col>
        </vs-row>
        
        
        
        <vs-divider/>
        <vs-row vs-type="flex" vs-justify="flex-end">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
                <vs-button color="success" type="filled" @click="addOrEditHoliday">{{holidayPopupAction}}</vs-button>&nbsp;
                <vs-button color="danger" type="filled" @click="holidayPopupActivo = false">Cancel</vs-button>
            </vs-col>
        </vs-row>
        
    </vs-popup>
</div>
    
</template>

<script>
import axios from "axios"
import moment from "moment"
import _ from "lodash"
import constants from "../../../constants.json"
export default {
    data:()=>({
        logged_in_team: "",
        holiday_list:[

        ],
        table_headings: [
            "Holiday",
            "Date",
            "Day"
        ],
        popupActivo: false,
        selectedHoliday: null,
        selectedCity: null,
        holidayFormData: {
            holiday_id: null,
            holiday_name: '',
            holiday_date: ''
        },
        holidayPopupActivo: false,
        holidayPopupAction: 'Add'
    }),
    methods: {
        getHolidayList: function() {
            this.holiday_list = []
            this.table_headings = ["Holiday","Date","Day"]
            axios.get(`${constants.SERVER_API}holiday_list`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        }).then((response) => {
                console.log('holiday list', response.data)
                const holidayData = response.data
                this.getTableHeader(holidayData)
                this.generateHolidayDataForDispaly(holidayData)
            })
        },
        getTableHeader(holidayData) {
            if (holidayData.length > 0) {
                const holiday = holidayData[0]
                const cities = _.sortBy(holiday.cities, 'name')
                const city_abbreviations = cities.map(city => city.code)
                this.table_headings = this.table_headings.concat(city_abbreviations)
                
            }
        },
        generateHolidayDataForDispaly(holidayData){
            this.holiday_list = [];
            if (holidayData.length > 0) {
                for (let i = 0; i < holidayData.length; i++) {
                    let holiday = holidayData[i];
                    holiday.displayDate = moment.unix(holiday.date).format("Do MMM YY")
                    holiday.displayDay = moment.unix(holiday.date).format("dddd")
                    holiday.cities = _.sortBy(holiday.cities, "name")
                    this.holiday_list.push(holiday)
                }
                this.holiday_list = _.sortBy(this.holiday_list, 'date')
            }
        },
        updateHoliday(holiday, city){
            if(this.logged_in_team != 'HR'){
                this.$vs.notify({
                    text: "You are not Authorized to Update",
                    color: "warning",
                });
                return;
            }
            this.selectedHoliday = holiday
            this.selectedCity = city
            this.popupActivo = true
        },
        closePopup(){
            this.popupActivo = false
        },
        postToServer() {
            const data = {
                holiday_id: this.selectedHoliday.id,
                city_id: this.selectedCity.id,
            }
            axios.post(`${constants.SERVER_API}updateHoliday`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
            .then((response) => {
                if (response.data === 'success') {
                    this.$vs.notify({
                        title: "Success",
                        text: "Holiday Updated",
                        color: "primary",
                    });
                    this.popupActivo = false
                    this.getHolidayList()
                }
            }).catch((e) => {
                console.log(e)
                this.$vs.notify({
                    title: "Error",
                    text: "Holiday Updation failed",
                    color: "danger",
                });
            })
        },
        addHoliday(){
            this.holidayFormData = {
                holiday_id: null,
                holiday_name: '',
                holiday_date: ''
            };
            this.holidayPopupAction = 'Add'
            this.holidayPopupActivo = true
        },
        editHoliday(holiday) {
            this.holidayFormData = {
                holiday_id: holiday.id,
                holiday_name: holiday.name,
                holiday_date: moment.unix(holiday.date).format('YYYY-MM-DD')
            };
            this.holidayPopupAction = 'Edit'
            this.holidayPopupActivo = true

        },
        addOrEditHoliday(){
            let method = 'post'
            let url = `${constants.MILES_CM_BACK}addHoliday`
            if (this.holidayPopupAction === 'Edit') {
                method = 'put'
                url = `${constants.MILES_CM_BACK}holiday/` + this.holidayFormData.holiday_id 
            }
            axios({
                method: method,
                url: url,
                data: this.holidayFormData,
                headers: {
                    Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
            })
            .then((response) => {
                console.log("addHoliday",response);
                if (response.data === 'success') {
                    this.$vs.notify({
                        title: "Success",
                        text: `Holiday ${this.holidayPopupAction}ed`,
                        color: "primary",
                    });
                    this.popupActivo = false
                    this.getHolidayList()
                }
                if (response.data.message === 'Successfully created Holiday') {
                    this.$vs.notify({
                        title: "Success",
                        text: response.data.message,
                        color: "primary",
                    });
                    this.holidayPopupActivo = false;
                    this.getHolidayList()
                }
            }).catch((e) => {
                console.log(e)
                this.$vs.notify({
                    title: "Error",
                    text: `Holiday ${this.holidayPopupAction} failed`,
                    color: "danger",
                });
            })
        }
    },
    mounted(){
        this.logged_in_team = localStorage.getItem('team');
        this.getHolidayList();

        console.log("logged in team",this.logged_in_team );
    }
}
</script>

<style>

</style>