<template>
    <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <display></display>
    </vs-col>
    </vs-row>
</template>

<script>
import display from "../../components/holidays/Dispaly.vue"
export default {
    components:{
        display
    }
}
</script>

<style>

</style>